import environment from './config.env.js';

const config = {
  getEnv: (
    key:
      | 'NODE_ENV'
      | 'PUBLIC_URL'
      | 'REACT_APP_BASE_URL'
      | 'REACT_APP_FULL_NAME'
      | 'REACT_APP_ADDRESS'
      | 'REACT_APP_NAME'
      | 'REACT_APP_VERSION'
      | 'REACT_APP_BASENAME'
      | 'REACT_APP_URL'
      | 'WDS_SOCKET_HOST'
      | 'WDS_SOCKET_PATH'
      | 'WDS_SOCKET_PORT'
      | string,
  ) => {
    if (environment && environment[key]) return environment[key];
    return '';
  },
};

export default config;
