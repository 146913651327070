/* eslint-disable consistent-return */
import axios from 'axios';

import config from './config/environmentConfig';

const VALIDATE_CONFIG_PROPERTIES = ['REACT_APP_BASE_URL'];

const validateConfig = () => {
  VALIDATE_CONFIG_PROPERTIES.forEach((key: string) => {
    const val = config.getEnv(key);
    if (!val) throw new Error(`App config must define ${key}`);
  });
};

const Boot = () =>
  new Promise((resolve) => {
    validateConfig();
    axios.defaults.baseURL = config.getEnv('REACT_APP_BASE_URL');
    // interceptors();
    resolve(true);
  });

export default Boot;
