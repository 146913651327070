import React, { FC, useMemo } from 'react';

import { Box } from '@material-ui/core';

import logoImg from '../../assets/logo.png';
import { Footer } from '../../components/Footer';
import config from '../../config/environmentConfig';
import { useStyles } from './styles';

const Authentication: FC = ({ children }) => {
  const appConfig = useMemo(() => {
    return {
      version: config.getEnv('REACT_APP_VERSION'),
      appName: config.getEnv('REACT_APP_NAME'),
      appFullName: config.getEnv('REACT_APP_FULL_NAME'),
    };
  }, []);

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.bgHeader} />
        <Box className={classes.bgHeaderBolinhas} />
        <img src={logoImg} alt={appConfig.appName} className={classes.logo} />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        padding="48px 35px 35px"
        width="100%"
        maxWidth="400px"
        margin="0 auto"
      >
        {children}
        <Footer version={appConfig.version} />
      </Box>
    </Box>
  );
};

export default Authentication;
