import React from 'react';

import { Box, BoxProps, Typography, Link } from '@material-ui/core';

import assinaturaImg from '../../assets/interativa.png';

interface FooterProps extends BoxProps {
  name?: string;
  version?: string;
}

export const Footer: React.FC<FooterProps> = ({ name, version, ...rest }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <Box
        color="secondary.light"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        width={1}
      >
        <Typography component="div">
          {name && <Box fontSize="body2.fontSize">{name}</Box>}
          {version && (
            <Box fontSize="caption.fontSize" fontWeight="fontWeightMedium">
              Versão {version}
            </Box>
          )}
        </Typography>

        <Link
          href="https://www.interativadigital.com.br/"
          title="Interativa Digital - Desde 1998 desenvolvendo os melhores projetos para Internet."
          target="_blank"
        >
          <img src={assinaturaImg} alt="Interativa" width="70px" />
        </Link>
      </Box>
    </Box>
  );
};
